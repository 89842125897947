import $ from 'jquery';
import Timer from '../../components/timer';
import VideoModalButton from '../../components/video-modal-button';
import MainSlider from './components/main-slider';
import RevealWhenVisible from '../../components/reveal-when-visible';
$(() => {
    MainSlider.init();
    Timer.init();
    RevealWhenVisible.init();
    VideoModalButton.init();
});
