import { __decorate } from "tslib";
import $ from 'jquery';
import { throttle } from 'helpful-decorators';
import { disableAllInputs, enableAllInputs } from '../utils/input';
export default class Timer {
    static $timer;
    static timer;
    static $timersExpireState = [];
    static stop() {
        clearInterval(this.timer);
    }
    static init() {
        this.$timer = $('.js__timer');
        if (!this.$timer.length) {
            return;
        }
        this.timer = setInterval(() => {
            this.timeChangeHandler(this.timer);
        }, 1000);
        this.setTimersExpireState();
        this.timeChangeHandler(this.timer);
        $(window).on('resize', () => {
            this.actionsOnResize();
        });
        this.actionsOnResize();
    }
    static setTimersExpireState() {
        this.$timer.each(index => {
            this.$timersExpireState[index] = false;
        });
    }
    static timeChangeHandler(timer) {
        const now = new Date().getTime();
        this.$timer.each((index, element) => {
            const $timer = $(element);
            let timeLeft = $timer.data('seconds-left');
            if (timeLeft) {
                timeLeft = this.calculateTimeLeft(timeLeft * 1000);
            }
            else {
                const timerEndDate = new Date($timer.data('end-year'), $timer.data('end-month') - 1, $timer.data('end-day'), $timer.data('end-hours'), $timer.data('end-minutes'), $timer.data('end-seconds')).getTime();
                const distance = timerEndDate - now;
                timeLeft = this.calculateTimeLeft(distance);
            }
            const { hideElementOnExpire, showElementOnExpire, enableFieldsOnExpire, disableFieldsOnExpire, disableEnableButtonsOnExpire, } = $timer.data();
            if (timeLeft.distance >= 0) {
                this.setTimer($timer, timeLeft.days, timeLeft.hours, timeLeft.minutes, timeLeft.seconds);
                if ($timer.data('seconds-left')) {
                    $timer.data('seconds-left', $timer.data('seconds-left') - 1);
                }
            }
            else if (!this.$timersExpireState[index]) {
                $(showElementOnExpire).removeClass('d-none');
                $(hideElementOnExpire).addClass('d-none').removeClass('d-flex');
                disableAllInputs($(disableFieldsOnExpire), disableEnableButtonsOnExpire);
                enableAllInputs($(enableFieldsOnExpire), disableEnableButtonsOnExpire);
                this.$timersExpireState[index] = true;
            }
        });
    }
    static setTimer($timer, days, hours, minutes, seconds) {
        const $days = $timer.find('.js__timer-days');
        const $hours = $timer.find('.js__timer-hours');
        const $minutes = $timer.find('.js__timer-minutes');
        const $seconds = $timer.find('.js__timer-seconds');
        $days.html(this.convertToTwoDigitNumber(days));
        $hours.html(this.convertToTwoDigitNumber(hours));
        $minutes.html(this.convertToTwoDigitNumber(minutes));
        $seconds.html(this.convertToTwoDigitNumber(seconds));
    }
    static calculateTimeLeft(distance) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        return { days, hours, minutes, seconds, distance };
    }
    static convertToTwoDigitNumber(value) {
        return (value < 10 ? '0' : '') + value;
    }
    static actionsOnResize() {
        this.$timer.each((index, element) => {
            const $element = $(element);
            const $elementWrapper = $element.closest('.js__timer-wrapper');
            const topOffset = $elementWrapper.data('top-offset');
            const leftOffset = $elementWrapper.data('left-offset');
            const elementSizeWrapperWidth = $element.closest('.js__timer-size-wrapper').outerWidth();
            const fontSize = $element.data('font-size');
            if (topOffset && leftOffset) {
                $elementWrapper.css({ top: topOffset, left: leftOffset });
            }
            if (elementSizeWrapperWidth && fontSize) {
                $element.css('font-size', (elementSizeWrapperWidth / 100) * fontSize);
                $element.find('.js__timer-label').css('font-size', ((elementSizeWrapperWidth / 100) * fontSize) / 4);
            }
        });
    }
}
__decorate([
    throttle(300)
], Timer, "actionsOnResize", null);
