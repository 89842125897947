import lozad from 'lozad';
import SwiperCore, { Navigation, Lazy, EffectFade } from 'swiper/core';
import Swiper from 'swiper';
import GaPromotionTracking, { GaPromotionEventType } from '../../../core/components/ga-promotion-tracking';
export default class MainSlider {
    static heroSlider;
    static isNextArrowDisabled = false;
    static isPrevArrowDisabled = true;
    static $nextButton = $('.js__swiper-button-next');
    static $prevButton = $('.js__swiper-button-prev');
    static swiperOptions = {
        speed: 600,
        lazy: true,
        navigation: {
            nextEl: '.js__swiper-button-next',
            prevEl: '.js__swiper-button-prev',
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true,
        },
    };
    static init() {
        const isSingleSlide = $('.js__swiper-hero .swiper-slide').not('.swiper-slide-duplicate').length === 1;
        this.swiperOptions.on = {
            init: () => {
                this.setNavigationBackground();
                this.trackPromotionOnActiveSlide();
                const el = document.querySelectorAll('.js__lazy-video');
                const observer = lozad(el);
                observer.observe();
                // timeout needed to set arrow loop after slider is fully initialized
                setTimeout(() => {
                    this.setLoopPrev();
                });
            },
            slideNextTransitionStart: () => {
                this.setNavigationBackground();
                this.trackPromotionOnActiveSlide();
                this.setLoopNext();
            },
            slidePrevTransitionStart: () => {
                this.setNavigationBackground();
                this.trackPromotionOnActiveSlide();
                this.setLoopPrev();
            },
        };
        SwiperCore.use([Navigation, Lazy, EffectFade]);
        this.heroSlider = new Swiper('.js__swiper-hero', this.swiperOptions);
        if (isSingleSlide) {
            $('.js__swiper-button-next, .js__swiper-button-prev').hide();
        }
    }
    static setNavigationBackground() {
        const $activeSlide = $('.swiper-slide-active');
        const firstColor = $activeSlide.data('bg-color-1');
        const secondColor = $activeSlide.data('bg-color-2');
        $('.js__nav-item-link-wrapper').each((index, element) => {
            const $wrapper = $(element);
            const $background = $(element).find('.js__nav-item-background');
            $($background.get(1)).remove();
            $wrapper.prepend($background
                .first()
                .clone()
                .css({ background: `linear-gradient(60deg, ${firstColor} 0%, ${secondColor} 100%)` }));
            $background.first().css({ opacity: 0 });
        });
    }
    static trackPromotionOnActiveSlide() {
        const $activeSlide = $('.swiper-slide-active');
        GaPromotionTracking.trackEvent($activeSlide, GaPromotionEventType.View, true);
    }
    static setLoopPrev() {
        let arrowClick;
        this.$prevButton.off(arrowClick);
        this.isNextArrowDisabled = false;
        this.isPrevArrowDisabled = this.$prevButton.hasClass('swiper-button-disabled');
        if (this.isPrevArrowDisabled) {
            arrowClick = this.$prevButton.on('click', () => {
                this.heroSlider.slideTo(this.heroSlider.slides.length - 1);
                this.isNextArrowDisabled = true;
                this.isPrevArrowDisabled = false;
            });
        }
    }
    static setLoopNext() {
        let arrowClick;
        this.$nextButton.off(arrowClick);
        this.isNextArrowDisabled = this.$nextButton.hasClass('swiper-button-disabled');
        this.isPrevArrowDisabled = false;
        if (this.isNextArrowDisabled) {
            arrowClick = this.$nextButton.on('click', () => {
                this.heroSlider.slideTo(0);
                this.isPrevArrowDisabled = true;
                this.isNextArrowDisabled = false;
            });
        }
    }
}
