import $ from 'jquery';
export var GaEventTrackType;
(function (GaEventTrackType) {
    GaEventTrackType["View"] = "view";
    GaEventTrackType["Click"] = "click";
})(GaEventTrackType || (GaEventTrackType = {}));
const ELEMENT_SELECTOR = '.js__ga-track';
export default class GaTracking {
    static $elements = $(ELEMENT_SELECTOR);
    static init() {
        this.$elements.each((index, element) => {
            const $element = $(element);
            const { trackType, dataLayer } = $element.data();
            if (trackType === GaEventTrackType.View) {
                this.pushDataLayer(dataLayer);
            }
        });
        $('body').on('click', ELEMENT_SELECTOR, e => {
            const $element = $(e.currentTarget);
            const { trackType, dataLayer, isMultipleEvents } = $element.data();
            const href = $element.attr('href');
            if (href) {
                e.preventDefault();
                if ($element.attr('target') === '_blank') {
                    window.open(href, '_blank');
                }
                else {
                    window.location.href = href;
                }
            }
            if (trackType === GaEventTrackType.Click) {
                if (isMultipleEvents) {
                    dataLayer.forEach((datalayer) => {
                        this.pushDataLayer(datalayer);
                    });
                }
                else {
                    this.pushDataLayer(dataLayer);
                }
            }
        });
    }
    static pushDataLayer(dataLayer, isMultipleEvents) {
        if (dataLayer) {
            if (isMultipleEvents) {
                dataLayer.forEach((dataLayer) => {
                    window.dataLayer.push(dataLayer);
                });
            }
            else {
                window.dataLayer.push(dataLayer);
            }
        }
    }
}
