import $ from 'jquery';
import GaTracking from './ga-tracking';
export var GaPromotionEventType;
(function (GaPromotionEventType) {
    GaPromotionEventType["View"] = "View";
    GaPromotionEventType["Click"] = "Click";
})(GaPromotionEventType || (GaPromotionEventType = {}));
export default class GaPromotionTracking {
    static selector = '.js__ga-track-promotion';
    static init($root = $('body'), onlyViewEvents = false) {
        $root.find(GaPromotionTracking.selector).each((index, element) => {
            const $element = $(element);
            if (!$element.data('promotion-no-view')) {
                GaPromotionTracking.trackEvent($element, GaPromotionEventType.View);
            }
        });
        if (onlyViewEvents) {
            return;
        }
        $('body').on('click', this.selector, e => {
            const $element = $(e.currentTarget);
            if (!$element.data('promotion-no-click')) {
                GaPromotionTracking.trackEvent($element, GaPromotionEventType.Click);
            }
        });
    }
    static trackViewInElement($element) {
        $element.find(`${this.selector}:visible`).each((index, element) => {
            const $element = $(element);
            GaPromotionTracking.trackEvent($element, GaPromotionEventType.View);
        });
    }
    static trackEvent($element, type, forcePush = false) {
        const id = $element.data('promotion-id');
        const trackOnlyActiveSlide = $element.data('track-view-on-active-slide-only');
        if (type === GaPromotionEventType.View && trackOnlyActiveSlide && !forcePush) {
            return;
        }
        const name = $element.data('promotion-name');
        const creative = $element.data('promotion-creative');
        const position = $element.data('promotion-position');
        const event = `promotion${type}`;
        const key = `promo${type}`;
        GaTracking.pushDataLayer({
            event,
            ecommerce: {
                [key]: {
                    promotions: [
                        {
                            id,
                            name: name || id,
                            creative,
                            position,
                        },
                    ],
                },
            },
        });
    }
}
