import $ from 'jquery';
export default class RevealWhenVisible {
    static init($root = $('body')) {
        const $element = $root.find('.js__reveal-when-visible');
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    $(entry.target).addClass('revealed');
                    observer.unobserve(entry.target);
                }
            });
        }, {
            threshold: 0.5,
        });
        $element.each((index, element) => {
            observer.observe(element);
        });
    }
}
