import $ from 'jquery';
export const getInputValue = ($input) => {
    if (isCheckbox($input)) {
        return $input.is(':checked');
    }
    if (isRadio($input)) {
        return getAssociatedInputs($input).filter(':checked').val();
    }
    return $input.val();
};
export const getAssociatedInputs = ($input) => {
    return $(`input[name="${$input.attr('name')}"]`);
};
export const isCheckbox = ($input) => {
    return $input.is('[type="checkbox"]');
};
export const isRadio = ($input) => {
    return $input.is('[type="radio"]');
};
export const clearInputValue = ($input) => {
    if (isCheckbox($input) || isRadio($input)) {
        $input.prop('checked', false);
    }
    else {
        $input.val('');
    }
    $input.trigger('change');
};
export const disableAllInputs = ($container, disableButtons = false) => {
    const $inputs = $container.find(`input, select, textarea${disableButtons ? ', button' : ''}`);
    $inputs.each((index, element) => {
        disableInput($(element));
    });
};
export const enableAllInputs = ($container, disableButtons = false) => {
    const $inputs = $container.find(`input, select, textarea${disableButtons ? ', button' : ''}`);
    $inputs.each((index, element) => {
        enableInput($(element));
    });
};
export const disableInput = ($input) => {
    const $formGroup = $input.closest('.form-group');
    $formGroup.find('label, legend').addClass('disabled');
    $input.prop('disabled', true);
};
export const enableInput = ($input) => {
    const $formGroup = $input.closest('.form-group');
    $formGroup.find('label, legend').removeClass('disabled');
    $input.prop('disabled', false);
};
