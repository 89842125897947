import $ from 'jquery';
import ModalVideo from 'modal-video';
export default class VideoModalButton {
    static init($root = $('body')) {
        const $element = $root.find('.js__video-modal-button');
        $element.each((_, element) => {
            element.setAttribute('data-channel', 'video');
            element.setAttribute('data-video-url', element.href);
        });
        new ModalVideo('.js__video-modal-button');
    }
}
